<template>
  <div>
    <v-card class="elevation-0">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="8">
            <v-form ref="form">
              <v-toolbar dense class="elevation-0">
                <v-text-field
                  dense
                  class="mt-7 mr-2"
                  outlined
                  :rules="[(v) => !!v || 'Required']"
                  label="Add Header"
                  v-model="header_value"
                ></v-text-field>
                <v-btn :disabled="tableData.length!=0" color="primary" @click="validateMethod()"
                  >Add Header</v-btn
                >
              </v-toolbar>
            </v-form>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="8">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(headerObj, headerIDX) in tableHeader"
                      :key="headerIDX"
                      class="text-left"
                    >
                      {{ headerObj.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      v-for="(headerObj, headerIDX) in tableHeader"
                      :key="headerIDX"
                    >
                      <v-text-field
                        dense
                        class="mt-2"
                        outlined
                        v-if="headerIDX == 0 && headerObj.value != 'ACTION'"
                        v-model="headerObj.vmodel"
                      ></v-text-field>

                      <v-checkbox
                        v-if="headerIDX != 0 && headerObj.value != 'ACTION'"
                        dense
                        v-model="headerObj.vmodel"
                      ></v-checkbox>

                      <div
                        class="mt-n4"
                        v-if="headerObj.value == 'ACTION'"
                        @click="addValueToTheTable()"
                      >
                        <v-btn small color="primary"> Add </v-btn>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(dataObj, dataIDX) in tableData" :key="dataIDX">
                    <td
                      v-for="(headerObj, headerIDX) in tableHeader"
                      :key="headerIDX"
                    >
                      <div v-if="headerIDX != tableHeader.length - 1">
                        <span v-if="headerIDX == 0">{{
                          dataObj[headerObj.value]
                        }}</span>
                        <v-checkbox
                          v-else
                          dense
                          v-model="dataObj[headerObj.value]"
                        ></v-checkbox>
                      </div>
                      <v-btn
                        v-if="headerIDX == tableHeader.length - 1"
                        icon
                        @click="tableData.splice(dataIDX, 1)"
                        color="red"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {},
  data: () => ({
    header_value: "",

    tableData: [],
    tableHeader: [],
  }),
  methods: {
    addValueToTheTable() {
      console.log("Check tableHeader", this.tableHeader);
      let emptyObj = {};
      for (let i = 0; i < this.tableHeader.length; i++) {
        emptyObj[this.tableHeader[i].value] =
          i != this.tableHeader.length - 1
            ? this.tableHeader[i].vmodel
              ? this.tableHeader[i].vmodel
              : false
            : "";
      }
      this.tableData.push({ ...emptyObj });
      for (let i = 0; i < this.tableHeader.length; i++) {
        this.tableHeader[i].vmodel =
          i != this.tableHeader.length - 1 ? (i == 0 ? "" : false) : "";
      }
    },
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.tableHeader.push({
          ...{
            text: this.header_value,
            value: this.header_value,
          },
        });
        this.$refs.form.reset();
        let actionIndex = this.tableHeader.findIndex(
          (headerObj) => headerObj.value == "ACTION"
        );
        if (actionIndex != -1) {
          this.tableHeader.splice(actionIndex, 1);
        }
        this.tableHeader.push({
          ...{
            text: "ACTION",
            value: "ACTION",
          },
        });
      }
    },
  },
};
</script>
